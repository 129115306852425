<template>
  <div class="page-layout news-page">

    <HeaderIntroImage underlined :headerMetadata="pageData" />

    <div class="fullwidth" style="overflow: hidden; margin: 0;">
      <div class="container">
          <div class="list-wrapper">
          <FiltersAside @reset="reset">
            <input type="text" placeholder="Free search..." v-model="searchField" @input="searchAfterTyping" />
            
            <select name="country" id="" class="filters--dropdown" @input="addWG">
              <option value="all">Filter by Working Group</option>
              <option v-for="(l, ind) in wgList" :key="'lang'+ind" :value="l">{{ l }}</option>
            </select>
            <div class="active-filter icon-right close" v-for="(l, ind) in wgFilter" :key="'lang'+ind" @click="removeWG(l)">{{ l }}</div>

            <select name="country" id="" class="filters--dropdown" @input="addPJ">
              <option value="all">Filter by Project</option>
              <option v-for="(l, ind) in pjList" :key="'lang'+ind" :value="l">{{ l }}</option>
            </select>
            <div class="active-filter icon-right close" v-for="(l, ind) in pjFilter" :key="'targ'+ind" @click="removePJ(l)">{{ l }}</div>
          </FiltersAside>

          <div class="lists" v-if="this.loading">
            <br>
            <h3>Loading data ...</h3>
            <br>
          </div>

          <div class="lists" v-else>

            <div class="calltoaction--list" v-if="this.filteredCalls.length && !this.loading">
              <h2>Call to Action</h2>
              <ArticlesList nodetype="generic--list">
                <CallToActionCard v-for="(n, index) in filteredCalls" :key="'news'+index" addclass="pct"
                  :articleData="n" :cat="categoryData(n)" :routesData="routesData" />
              </ArticlesList>
              <button v-if="callsData.length>numLoadedCalls" class="btn slim light" @click="loadmoreCalls()"><span class="reset icon-right">Load more</span></button>
            </div>

            <br>
            
            <div>
              <h2 v-if="this.filteredNews.length">News</h2>
              <ArticlesList v-if="this.filteredNews.length" nodetype="generic--list">
                <keep-alive v-for="(n, index) in filteredNews" :key="'news'+index">
                  <ArticleCard v-if="n.attributes.field_news_type==1" 
                    addclass="pct" :articleData="n" :cat="categoryData(n)" :routesData="routesData" />
                  <CallToActionCard v-if="n.attributes.field_news_type==2" pastcall
                    addclass="pct" :articleData="n" :cat="categoryData(n)" :routesData="routesData" />
                </keep-alive>
                
              </ArticlesList>
              <button v-if="newsData.length>numLoadedNews" class="btn slim light" @click="loadmoreNews()"><span class="reset icon-right">Load more</span></button>
            </div>

            <div class="no-data" v-if="filteredNews.length==0 && filteredCalls.length==0">
              <h3>No Data</h3>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FiltersAside from '../components/layout-blocks/FiltersAside.vue'
import ArticlesList from '../components/layout-blocks/ArticlesList.vue'
import ArticleCard from '../components/layout-blocks/ArticleCard.vue'
import CallToActionCard from '../components/layout-blocks/CallToActionCard.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes } from '../libs/drupalClient'
export default {
  name: 'news',
  components: {
    HeaderIntroImage,
    ArticleCard,
    CallToActionCard,
    ArticlesList,
    FiltersAside
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "News"
        }
      },
      currentPageID: "",
      newsData: null,
      callsData: null,
      wgFilter: [],
      pjFilter: [],
      searchFilter: '',
      searchField: '',
      timer: undefined,
      loading: true,
      numLoadedNews: 3,
      numLoadedCalls: 3
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    routesData() {
      return this.$store.state.routesData
    },
    wgList() {
      var wgs = []

      if(this.newsData)
        this.newsData.forEach(n => {
          if(n.field_ref_working_group && !wgs.includes(n.field_ref_working_group.attributes.title))
            wgs.push(n.field_ref_working_group.attributes.title)
        })

      if(this.callsData)
        this.callsData.forEach(n => {
          if(n.field_ref_working_group && !wgs.includes(n.field_ref_working_group.attributes.title))
            wgs.push(n.field_ref_working_group.attributes.title)
        })

      return wgs.sort((a,b) => { return a>b ? 1 : -1 })
    },
    pjList() {
      var pjs = []

      if(this.newsData)
        this.newsData.forEach(n => {
          if(n.field_ref_project && !pjs.includes(n.field_ref_project.attributes.title))
            pjs.push(n.field_ref_project.attributes.title)
        })

      if(this.callsData)
        this.callsData.forEach(n => {
          if(n.field_ref_project && !pjs.includes(n.field_ref_project.attributes.title))
            pjs.push(n.field_ref_project.attributes.title)
        })

      return pjs.sort((a,b) => { return a>b ? 1 : -1 })
    },
    filteredCalls() {
      var fns = []

      if(this.callsData) {
        //fns = this.callsData
        fns = this.callsData.slice(0, this.numLoadedCalls)

        if(this.wgFilter.length)
          fns = fns.filter(n => {
            var found = false
            if(n.field_ref_working_group && this.wgFilter.includes(n.field_ref_working_group.attributes.title))
              found = true
            return found
          })

        if(this.pjFilter.length)
          fns = fns.filter(n => {
            var found = false
            if(n.field_ref_project && this.pjFilter.includes(n.field_ref_project.attributes.title))
              found = true
            return found
          })
        
        if(this.searchFilter.length) {
          fns = fns.filter(n => {
            return n.attributes.title.toLowerCase().includes(this.searchFilter.toLowerCase()) 
              //|| n.attributes.field_description.value.toLowerCase().includes(this.searchFilter.toLowerCase())
          })
        }
      }

      return fns
    },
    filteredNews() {
      var fns = []

      if(this.newsData) {
        //fns = this.newsData
        fns = this.newsData.slice(0, this.numLoadedNews)

        if(this.wgFilter.length)
          fns = fns.filter(n => {
            var found = false
            if(n.field_ref_working_group && this.wgFilter.includes(n.field_ref_working_group.title))
              found = true
            return found
          })

        if(this.pjFilter.length)
          fns = fns.filter(n => {
            var found = false
            if(n.field_ref_project && this.pjFilter.includes(n.field_ref_project.title))
              found = true
            return found
          })

        if(this.searchFilter.length) {
          fns = fns.filter(n => {
            return n.attributes.title.toLowerCase().includes(this.searchFilter.toLowerCase()) 
              //|| n.attributes.field_description.value.toLowerCase().includes(this.searchFilter.toLowerCase())
          })
        }
      }

      return fns
    }
  },
  methods: {
    loadmoreNews() {
      this.numLoadedNews += 3;
    },
    loadmoreCalls() {
      this.numLoadedCalls += 3;
    },
    addWG(event) {
      if(!this.wgFilter.includes(event.target.value))
        this.wgFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeWG(l) {
      var ind = this.wgFilter.indexOf(l)
      this.wgFilter.splice(ind, 1)
    },
    addPJ(event) {
      if(!this.pjFilter.includes(event.target.value))
        this.pjFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removePJ(l) {
      var ind = this.pjFilter.indexOf(l)
      this.pjFilter.splice(ind, 1)
    },
    searchAfterTyping() {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        //console.log('searching', this.searchField)
        this.searchFilter = this.searchField
      }, 300);
    },
    reset() {
      this.wgFilter = []
      this.pjFilter = []
      this.searchField = ''
      this.searchFilter = ''
    },
    getWG(id) {
      return this.$store.getters.getWG(id).name
    },
    categoryData(n) {
      var cd = {
        nid: 0,
        name: ""
      }

      if(n.field_ref_working_group)
        cd = this.$store.getters.getWG(n.field_ref_working_group.attributes.drupal_internal__nid)

      return cd
    },
    async findCT() {
      this.loading = true

      await fetchNodes('news', {
        include: ['field_ref_working_group', 'field_image', 'field_ref_project']
      })
      .then(res => {
        var sorted = res.sort((a,b) => {
          return a.attributes.field_date > b.attributes.field_date ? -1 : 1
        })

        this.newsData = sorted.filter(n => 
          n.attributes.field_news_type==1
          || (n.attributes.field_news_type==2 && new Date(n.attributes.field_opportunity_deadline) < Date.now())
        )
        this.callsData = sorted.filter(n => 
          n.attributes.field_news_type==2 
          && new Date(n.attributes.field_opportunity_deadline) >= Date.now()
        )

       //console.log('news', this.newsData)
       //console.log('call to actions', this.callsData)
      })

      this.loading = false
    }
  },
  async mounted() {
    this.reset()

    await this.findCT()

    if(this.$store.state.add_wg_filter)
      this.wgFilter.push(this.$store.state.add_wg_filter)

    this.$store.commit('SET_WG_FILTER', '')
  },
  watch: {
    async categoryData() {
      await this.findCT()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.news-page ::v-deep .news.calltoaction .article--thumbnail:before{
  height: 20px;
}

.news-page ::v-deep .news.calltoaction.past .article--thumbnail:before{
  height: .5rem;
}

.active-filter {
  padding: .25em;
  font-size: 14px;
  padding-right: 1.5em;
  color: $middleBlue;
  &:before { padding-right: .5em; }
  &:hover {
    background-color: $middleBlue;
    color: #fff;
    cursor: pointer;
  }
}

.btn.slim.light { margin: 1em auto; }
</style>